/* eslint-disable react/display-name */
import OverlayMessageStyles from '../../styles/elements/OverlayMessages.module.scss';
import { PortableText } from '../../lib/sanity';

const serializers = {
  marks: {
    link: ({ children, mark }) =>
      mark.blank ? (
        <a href={mark.href} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ) : (
        <a href={mark.href}>{children}</a>
      )
  }
};

const OverlayMessage = ({ CompanyInfo }) => {
  return (
    <div className={OverlayMessageStyles.holder}>
      <h3>{CompanyInfo.overlaytitle}</h3>

      <PortableText blocks={CompanyInfo.message} serializers={serializers} />
    </div>
  );
};

export default OverlayMessage;
