import PinkBarStyles from '../styles/PinkBarStyles.module.scss';

function PinkBar({ message }) {
  return (
    <div className={PinkBarStyles.holder}>
      <div className="container" style={{paddingTop: '0', paddingBottom: '0'}}>
        <h3>{message}</h3>
      </div>
    </div>
  );
}

export default PinkBar;
