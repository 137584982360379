/* eslint-disable @next/next/no-img-element */

import IconStyles from '../../styles/footer/SocialIconStyles.module.scss';

import Link from 'next/link';

const SocialIcons = ({ CompanyInfo }) => {
  return (
    <div className={IconStyles.socialholder}>
      <a href="https://www.facebook.com/groups/more2childcare" target="_blank" rel="noreferrer">
        <img src="/images/static/social/facebook.svg" alt="Facebook Social Icon" width="40" height="40" loading="lazy" />
      </a>

      <a href="https://twitter.com/more2childcare" target="_blank" rel="noreferrer">
        <img src="/images/static/social/twitter.svg" alt="Twitter Social Icon" width="40" height="40" loading="lazy" />
      </a>

      <a href="https://www.instagram.com/more2nurseries/" target="_blank" rel="noreferrer">
        <img src="/images/static/social/instagram.svg" alt="Instagram Social Icon" width="40" height="40" loading="lazy" />
      </a>

      <a href="https://www.youtube.com/channel/UCoCqT3wF1U5hUmEuHVS9S6w" target="_blank" rel="noreferrer">
        <img src="/images/static/social/youtube.svg" alt="Youtube Social Icon" width="40" height="40" loading="lazy" />
      </a>
    </div>
  );
};

export default SocialIcons;
