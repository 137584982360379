/* eslint-disable @next/next/no-img-element */
import IntroSocialStyles from '../../styles/footer/IntroSocial.module.scss';
import Link from 'next/link';
import SocialIcons from '../../components/elements/SocialIcons';

function DefaultInfo({ CompanyInfo}) {

  return (
    <div className={IntroSocialStyles.firstblock}>
      <div className={IntroSocialStyles.logo1}>
        <img src="/images/static/more2-logo-white.svg" alt="More Nurseries Logo" className="img-fluid" width="130" height="85" loading="lazy" />
      </div>
      <SocialIcons CompanyInfo={CompanyInfo[0].sociallinks} />
      <p className={IntroSocialStyles.desc}>An award winning childcare provider with two nurseries in Greenwich, South East London. More2Childcare (Ofsted Outstanding) and More2Riverside which opened in 2019.</p>
    </div>
  );
}

export default DefaultInfo;
