import copyrightStyles from '../../styles/footer/Copyright.module.scss';
import Link from 'next/link';
import DefaultInfo from './DefaultInfo';


let getYear = () => {
  let currentYear = new Date().getFullYear();
  return currentYear;
};

const Copyright = () => {
  return (
    <div className={copyrightStyles.copyright}>
      <p>Copyright © {getYear()} More2Child Care. All Rights Reserved. <Link href="/cookies" >Cookies</Link>. <a href="https://cdn.sanity.io/files/9g8ar69c/production/771ac59f22ec13b6717c0876f62be64483a9eecc.pdf?dl=Privacy-Policy.pdf" target="_blank" rel="noreferrer">Privacy Policy</a>.</p>
    </div>
  );
};

export default Copyright;
