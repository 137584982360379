/* eslint-disable @next/next/no-img-element */
import ContactStyles from '../../styles/footer/ContactDetails.module.scss';
import Link from 'next/link';

function DefaultInfo({ CompanyInfo }) {

  return <>
    <div className={ContactStyles.secondblock}>
      <div>
        <h2>Contact Us</h2>
        <div className={ContactStyles.contactlinks}>

        {CompanyInfo[0].email?.length > 0 &&
              CompanyInfo[0].email.map((emails) => (
          <Link
            href={`mailto:${emails.link}`}

            key={emails._key}
            >
            <button className={ContactStyles.mailTo}>{emails.title}</button>
          </Link>
          ))}
          {CompanyInfo[0].numbers?.length > 0 &&
              CompanyInfo[0].numbers.map((number) => (
          <Link
            href={`tel:${number.link}`}
            key={number._key}
            >
            <button className={ContactStyles.telTo}>{number.title}</button>
          </Link>
           ))}
        </div>
        <div className={ContactStyles.footernavlinks}>
          <div>
            <ul>

            {CompanyInfo[0].footer?.length > 0 &&
              CompanyInfo[0].footer.map((contactlinks) => (
              <li key={contactlinks._key}>
                <Link href={contactlinks.link} >
                  {contactlinks.title}
                </Link>
              </li>
              ))}

            </ul>
          </div>
        </div>
      </div>
    </div>
  </>;
}

export default DefaultInfo;

