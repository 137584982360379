import MenuStyles from '../../styles/navigation/MenuAccordionStyles.module.scss';
import Link from 'next/link';

const NavMenuAccordion = ({ isOpen, setIsOpen, Nurseries }) => {
  // console.log("🚀 ~ NavMenuAccordion ~ Nurseries:", Nurseries)
  const toggle = () => {
    setIsOpen(!isOpen);
  };



  return (
    <div className={MenuStyles.holder}>
      <div className={MenuStyles.row}>
        <div className={MenuStyles.col}>
          <div className={MenuStyles.tabs}>
            <div className={MenuStyles.tab}>
              <Link href="/">

                <div className={MenuStyles.standardLink} onClick={toggle}>
                  Home
                </div>

              </Link>
            </div>
            <div className={MenuStyles.tab}>
              <input type="checkbox" id="chck2" />
              <label className={MenuStyles.tablabel} htmlFor="chck2">
                <div className={MenuStyles.standardLink}>About</div>
              </label>
              <div className={MenuStyles.tabcontent}>
                <Link href="/about-us" onClick={toggle}>
                  Overview
                </Link>
                <Link href="/about-us/our-vision" onClick={toggle}>
                  Our Vision
                </Link>
                <Link href="/about-us/food" onClick={toggle}>
                  Food
                </Link>
                <Link href="/about-us/outdoor-space" onClick={toggle}>
                  Outdoor space
                </Link>
                <Link href="/about-us/curriculum" onClick={toggle}>
                  Curriculum
                </Link>
                <Link href="/about-us/community" onClick={toggle}>
                  Community
                </Link>
                <Link href="/about-us/staff" onClick={toggle}>
                  Staff
                </Link>
                <Link href="/about-us/awards" onClick={toggle}>
                  Awards
                </Link>
              </div>
            </div>
            <div className={MenuStyles.tab}>
              <input type="checkbox" id="chck3" />
              <label className={MenuStyles.tablabel} htmlFor="chck3">
                <div className={MenuStyles.standardLink}>Nurseries</div>
              </label>
              <div className={MenuStyles.tabcontent}>
                <Link href="/nurseries/more2childcare-nursery" onClick={toggle}>
                  More2ChildCare
                </Link>
                <Link href="/nurseries/more2riverside-nursery" onClick={toggle}>
                  More2Riverside
                </Link>
                <Link
                  href={`${Nurseries[0].manuscriptURL}?dl=`}
                  onClick={toggle}>

                  Prices
                </Link>
                <Link href="/nurseries/policies" onClick={toggle}>
                  Policies
                </Link>

                <Link href="/nurseries/faq" onClick={toggle}>
                  FAQs
                </Link>
                {/* <Link href="/nurseries/coronavirus">
                  <a onClick={toggle}>Coronavirus</a>
                </Link> */}
              </div>
            </div>
            <div className={MenuStyles.tab}>
              <input type="checkbox" id="chck4" />
              <label className={MenuStyles.tablabel} htmlFor="chck4">
                <div className={MenuStyles.standardLink}>Admissions</div>
              </label>
              <div className={MenuStyles.tabcontent}>
                <Link href="/admissions/book-a-tour" onClick={toggle}>
                  Secure Your Place
                </Link>
                <Link href="/admissions/nursery-tour-booking" onClick={toggle}>
                  Nursery Tour Booking Form
                </Link>
                <Link href="/admissions/register-your-child" onClick={toggle}>
                  Register your Child
                </Link>
                <Link href="/admissions/more2membership" onClick={toggle}>
                  More2Membership
                </Link>
              </div>
            </div>
            <div className={MenuStyles.tab}>
              <input type="checkbox" id="chck5" />
              <label className={MenuStyles.tablabel} htmlFor="chck5">
                <div className={MenuStyles.standardLink}>Parents</div>
              </label>
              <div className={MenuStyles.tabcontent}>
                <Link href="/parents/more2parents" onClick={toggle}>
                  More2Parents
                </Link>
                <Link href="/parents/our-partners" onClick={toggle}>
                  Our Partners
                </Link>
                {/* <Link href="/parents/events">
                  <a onClick={toggle}>Events</a>
                </Link> */}
                {/* <Link href="#">
                  <a onClick={toggle}>Subject Guides</a>
                </Link> */}
              </div>
            </div>
            <div className={MenuStyles.tab}>
              <input type="checkbox" id="chck6" />
              <label className={MenuStyles.tablabel} htmlFor="chck6">
                <div className={MenuStyles.standardLink}>Careers</div>
              </label>
              <div className={MenuStyles.tabcontent}>
                <Link href="/careers/more2-careers" onClick={toggle}>
                  More2 Careers
                </Link>
                <Link href="/careers/open-vacancies" onClick={toggle}>
                  Open Vacancies
                </Link>
                <Link href="/careers/staff-testimonials" onClick={toggle}>
                  Staff Testimonials
                </Link>
              </div>
            </div>
            <div className={MenuStyles.tab}>
              <Link href="/blog">

                <div className={MenuStyles.standardLink} onClick={toggle}>
                  Blog
                </div>

              </Link>
            </div>
            <div className={MenuStyles.tab}>
              <Link href="/contact">

                <div className={MenuStyles.standardLink} onClick={toggle}>
                  Contact
                </div>

              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavMenuAccordion;
