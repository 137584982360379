/* eslint-disable @next/next/no-img-element */
import DefaultFooterStyles from '../../styles/footer/DefautlFooter.module.scss';
import IntroSocial from './IntroSocial';
import ContactDetails from './ContactDetails';

function DefaultInfo({CompanyInfo}) {

  return (
    <div className={DefaultFooterStyles.holder}>
      <div className={DefaultFooterStyles.IntroSocial}>
        <IntroSocial CompanyInfo={CompanyInfo}  />
      </div>
      <div className={DefaultFooterStyles.vertseperator}></div>
      <div className={DefaultFooterStyles.ContactDetails}>
        <ContactDetails CompanyInfo={CompanyInfo} />
      </div>
    </div>
  );
}

export default DefaultInfo;
