/* eslint-disable react/display-name */
/* eslint-disable @next/next/no-img-element */
import MapBlockStyles from '../../styles/footer/MapBlockStyles.module.scss';

import Link from 'next/link';

import { sanityClient, PortableText, urlFor } from '../../lib/sanity';

const serializers = {
  marks: {
    link: ({ children, mark }) =>
      mark.blank ? (
        <a href={mark.href} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ) : (
        <a href={mark.href}>{children}</a>
      )
  }
};

const MapBlocks = ({ Nurseries }) => {
  return (
    <div className={MapBlockStyles.holder}>
      <div className={MapBlockStyles.mapblock}>
        <div className={MapBlockStyles.logo}>
          <img src="/images/static/logo_childcare_teal.svg" alt="More2Childcare Logo" className={`more2logo`} width="130" height="85" loading="lazy" />
        </div>
        <div className={MapBlockStyles.details}>
          <h3>More2ChildCare</h3>

          <PortableText blocks={Nurseries[0].address} serializers={serializers} />
          <Link href={Nurseries[0].linkaddress}>
            <button className="webchildcare">Webpage</button>
          </Link>
        </div>
        <div className={MapBlockStyles.mapimage}>
          <Link href="/contact">
            <img src={urlFor(Nurseries[0].mapimage).auto('format').width(680).height(245).url()} alt="More2ChildCare Map Image" width={680} height={245} loading="lazy" />
          </Link>
        </div>
      </div>
      <div className={MapBlockStyles.mapblock}>
        <div className={MapBlockStyles.logo}>
          <img src="/images/static/logo_riverside_pink.svg" alt="More2Riverside Logo" className={`more2logo`} width="130" height="85" loading="lazy" />
        </div>
        <div className={MapBlockStyles.details}>
          <h3>More2Riverside</h3>

          <PortableText blocks={Nurseries[1].address} serializers={serializers} />
          <Link href={Nurseries[1].linkaddress}>
            <button className="webriverside">Webpage</button>
          </Link>
        </div>
        <div className={MapBlockStyles.mapimage}>
          <Link href="/contact">
            <img src={urlFor(Nurseries[1].mapimage).auto('format').width(680).height(245).url()} alt="More2Riverside Map Image" width={680} height={245} loading="lazy" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MapBlocks;
