
import WBIllustrationStyles from '../../styles/elements/WBIllustration.module.scss'


const WhiteBlueIllustration = () => {
    return (
        <div className={WBIllustrationStyles.maincontainer}>

        </div>
    )
}

export default WhiteBlueIllustration
