import FooterStyles from '../../styles/footer/Footer.module.scss';
import DefaultInfo from './DefaultInfo';
import Copyright from './Copyright';
import MapBlocks from './MapBlocks';

const Footer = ({CompanyInfo, Nurseries}) => {

  return (
    <footer className={FooterStyles.main}>
      <div className={FooterStyles.container}>
        <DefaultInfo CompanyInfo={CompanyInfo}/>
      </div>
      <MapBlocks Nurseries={Nurseries} />
      <Copyright CompanyInfo={CompanyInfo} />
    </footer>
  );
};

export default Footer;
